import Immutable from "seamless-immutable";
import identity from "lodash/identity";

export const createAction = type => {
  const actionCreator = (payload = null) => ({
    type,
    payload,
    error: payload instanceof Error,
  });
  actionCreator.toString = () => type;

  return actionCreator;
};

export const createAsyncAction = type => {
  const actionCreator = (payload = null) => ({
    type,
    payload,
    error: payload instanceof Error,
  });
  actionCreator.toString = () => type;
  actionCreator.metaActions = {
    request: createAction(`${type}/request`),
    success: createAction(`${type}/success`),
    fail: createAction(`${type}/fail`),
  };

  return actionCreator;
};

export const createReducer = (handlers, initialState) => (
  state = Immutable(initialState),
  { type, payload }
) => {
  if (handlers.hasOwnProperty(type)) {
    return handlers[type](state, payload);
  }
  return state;
};

export const createAsyncHandlers = (
  actions,
  {
    onRequest = identity,
    onSuccess = identity,
    onFail = identity,
    path = [],
  } = {}
) => ({
  [actions.request]: (state, payload) =>
    onRequest(state.setIn([...path, "isLoading"], true), payload),
  [actions.success]: (state, payload) =>
    onSuccess(
      state
        .setIn([...path, "isLoading"], false)
        .setIn([...path, "isLoaded"], true)
        .setIn([...path, "data"], payload)
        .setIn([...path, "error"], undefined),
      payload
    ),
  [actions.fail]: (state, payload) =>
    onFail(
      state
        .setIn([...path, "isLoading"], false)
        .setIn([...path, "error"], payload),
      payload
    ),
});
