import { createQueueAction } from "./actions";
import { createAsyncHandlers, createReducer } from "../../utils/redux";

const initialState = {
  isCreating: null, // set to counter type id if creating
  error: null,
};

const handlers = {
  ...createAsyncHandlers(createQueueAction.metaActions, {
    onRequest: (state, payload) => state.set("isCreating", payload.countertype),
    onSuccess: state => state.set("isCreating", null).set("error", null),
    onFail: (state, payload) =>
      state.set("isCreating", null).set("error", payload),
  }),
};

export default createReducer(handlers, initialState);
