import Axios from "axios";

const instance = Axios.create({
  baseURL: "https://q-sys.herokuapp.com",
  timeout: 10000,
});

//=================================== Auth ===================================
export const postLogin = body => instance.post("auth/local", body);

export const setAuthHeader = token => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

//=================================== Queue ===================================
export const postQueue = body => instance.post("queues", body);
// export const getQueue = body => instance.get('queues', body);
