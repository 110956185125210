import { combineReducers } from "redux";
import queue from "./queue/reducer";
import account from "./account/reducer";
import global from "./globalReducer";
import { logoutAction } from "./account/actions";

const reducers = combineReducers({
  queue,
  account,
  global,
});

const rootReducer = (state = {}, action) => {
  if (action.type === logoutAction.toString()) {
    // reset all state
    return reducers({}, action);
  }

  return reducers(state, action);
};

export default rootReducer;
