export const STATE_DELETE = 0;
export const STATE_ADD = 1;
export const STATE_EDIT = 2;

//setting dialog type
export const SETTING_TYPE_EDIT = 0x51;
export const SETTING_TYPE_ADD = 0x52;
export const SETTING_TYPE_DELETE = 0x53;
export const SETTING_LOCKET_EDIT = 0x54;
export const SETTING_LOCKET_ADD = 0x55;
export const SETTING_LOCKET_DELETE = 0x56;

//redux and saga command
export const ACTION_GET_TYPE = "A1";
export const ACTION_GET_LOCKET = "A2";
export const ACTION_ADD_TYPE = "A3";
export const ACTION_ADD_LOCKET = "A4";
export const ACTION_GET_ALL_TYPE = "A6";
export const ACTION_DEL_TYPE = "A7";
export const ACTION_DEL_LOCKET = "A8";
export const ACTION_SEL_LOCKET = "A9";
export const ACTION_OPEN_SETTING_DIALOG = "AB";
export const ACTION_CLOSE_SETTING_DIALOG = "AC";
export const ACTION_EDIT_TYPE = "AE";
export const ACTION_EDIT_LOCKET = "AF";
export const ACTION_AVATAR_ID = "B0";
export const ACTION_ADD_QUEUE = "B1";
export const ACTION_LOGIN_STATE = "B2";
export const ACTION_CONTROL_PROPS = "B3";
export const SAGA_MESSAGE = "F0";
export const SAGA_TYPE = "F1";
export const SAGA_LOCKET = "F2";
export const SAGA_ALL_TYPE = "F3";
export const SAGA_USER = "F4";
