import { call, put, takeLatest } from "redux-saga/effects";
import { loginAction } from "./actions";
import { postLogin, setAuthHeader } from "../../services/api";

export function* login({ payload }) {
  const { request, success, fail } = loginAction.metaActions;
  yield put(request());
  try {
    const { data, error } = yield call(postLogin, payload);
    if (data) {
      setAuthHeader(data.jwt);
      yield put(success(data));
    } else {
      yield put(fail(error));
    }
  } catch (err) {
    yield put(fail(err));
  }
}

export default function* watchCreateQueue() {
  yield takeLatest(loginAction.toString(), login);
}
