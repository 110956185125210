import * as Const from "../Constants";

const initialState = {
  controlProps: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Const.ACTION_CONTROL_PROPS:
      console.log("action props = " + action.properties);
      return {
        ...state,
        controlProps: action.properties,
      };
    default:
      return state;
  }
};

export default reducer;
