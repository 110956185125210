import { loginAction } from "./actions";
import { createAsyncHandlers, createReducer } from "../../utils/redux";

const initialState = {
  isLoading: false,
  user: null,
  isLoggedIn: false,
};

const handlers = {
  ...createAsyncHandlers(loginAction.metaActions, {
    onSuccess: state => state.set("isLoggedIn", true),
  }),
};

export default createReducer(handlers, initialState);
