import { call, put, takeLatest } from "redux-saga/effects";
import { createQueueAction } from "./actions";
import { postQueue } from "../../services/api";

export function* createQueue({ payload }) {
  const { request, success, fail } = createQueueAction.metaActions;
  yield put(request(payload));

  try {
    const { data } = yield call(postQueue, payload);
    if (data)
      yield put(success());
  } catch (error) {
    yield put(fail(error));
  }
}

export default function* watchCreateQueue() {
  yield takeLatest(createQueueAction.toString(), createQueue);
}
