// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-counter-js": () => import("/opt/build/repo/src/templates/counter.js" /* webpackChunkName: "component---src-templates-counter-js" */),
  "component---src-pages-counter-control-js": () => import("/opt/build/repo/src/pages/CounterControl.js" /* webpackChunkName: "component---src-pages-counter-control-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/opt/build/repo/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-patient-js": () => import("/opt/build/repo/src/pages/patient.js" /* webpackChunkName: "component---src-pages-patient-js" */),
  "component---src-pages-settings-js": () => import("/opt/build/repo/src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

